import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import { Box } from '@mui/material';

import { Colors } from 'common/src/constants';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import SessionAccessor from 'common/src/system/session';

import { Page } from 'common/src/components/base';
import ScanResult from './ScanResult';

const SCANNER_OVERLAY_RATIO = 0.7;
const SCANNER_OVERLAY_BORDER_RATIO = 0.2;
const DEFAULT_SCAN_OVERLAY_COLOR = '#FFFFFF66';

export default function CheckInQRScannerNextScreen() {
  const navigate = useNavigate();

  const { windowWidth, windowHeight } = useWindowDimensions();
  const isMobile = screen.height > screen.width;
  const cameraHeight = Math.min(windowWidth, windowHeight * 0.5);
  const scanOverlaySize = cameraHeight * SCANNER_OVERLAY_RATIO;
  const scanOverlayBorderSize = scanOverlaySize * SCANNER_OVERLAY_BORDER_RATIO;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eventTemplateId = query.get('eventTemplateId');
  const accessToken = query.get('token');

  const [cameraLoaded, setCameraLoaded] = React.useState(false);

  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for CheckInQRScannerNextScreen');
  }

  React.useEffect(() => {
    if (accessToken) {
      SessionAccessor.setStandaloneSessionToken(accessToken);
    } else if (!SessionAccessor.getStandaloneSessionToken()) {
      navigate('/error');
    }
  }, [accessToken, navigate]);

  const [scannedData, setScannedData] = React.useState('');
  const { ref } = useZxing({
    constraints: {
      video: {
        facingMode: 'environment',
        // Mobile is portrait by default, so we need to swap width and height
        height: isMobile ? windowWidth : cameraHeight,
        width: isMobile ? cameraHeight : windowWidth,
      },
    },
    onResult(result) {
      const resultText = result.getText();
      if (!resultText) {
        return;
      }
      setScannedData(resultText);
    },
  });

  const [scanOverlayColor, setScanOverlayColor] = React.useState(DEFAULT_SCAN_OVERLAY_COLOR);
  const handleAction = React.useCallback((action: 'success' | 'error') => {
    if (action === 'success') {
      setScanOverlayColor('#6FD25A');
    } else {
      setScanOverlayColor(Colors.ERROR);
    }
    setTimeout(() => {
      setScanOverlayColor(DEFAULT_SCAN_OVERLAY_COLOR);
    }, 500);
  }, []);

  return (
    <Page>
      <Box display='flex' justifyContent='center'>
        <Box position='relative'>
          <video
            onLoadedData={() => setCameraLoaded(true)}
            ref={ref}
            autoFocus
          />

          {/* Square overlay on top */}
          {
            cameraLoaded && (
              <Box
                position='absolute'
                top={(cameraHeight-scanOverlaySize)/2}
                left={(windowWidth-scanOverlaySize)/2}
                width={scanOverlaySize}
                height={scanOverlaySize}
              >
                {/* Top-left corner */}
                <Box
                  position='absolute'
                  top={0}
                  left={0}
                  width={scanOverlayBorderSize}
                  height={scanOverlayBorderSize}
                  borderTop={4}
                  borderLeft={4}
                  borderColor={scanOverlayColor}
                />
                {/* Top-right corner */}
                <Box
                  position='absolute'
                  top={0}
                  right={0}
                  width={scanOverlayBorderSize}
                  height={scanOverlayBorderSize}
                  borderTop={4}
                  borderRight={4}
                  borderColor={scanOverlayColor}
                />
                {/* Bottom-left corner */}
                <Box
                  position='absolute'
                  bottom={0}
                  left={0}
                  width={scanOverlayBorderSize}
                  height={scanOverlayBorderSize}
                  borderBottom={4}
                  borderLeft={4}
                  borderColor={scanOverlayColor}
                />
                {/* Bottom-right corner */}
                <Box
                  position='absolute'
                  bottom={0}
                  right={0}
                  width={scanOverlayBorderSize}
                  height={scanOverlayBorderSize}
                  borderBottom={4}
                  borderRight={4}
                  borderColor={scanOverlayColor}
                />
              </Box>
            )
          }
        </Box>
      </Box>
      {cameraLoaded && (
        <Box mt={-7}>
          <ScanResult
            eventTemplateId={eventTemplateId}
            scannedData={scannedData}
            onAction={handleAction}
          />
        </Box>
      )}
    </Page>
  );
}
