import { Route, Routes } from 'react-router-dom';

import { UserRole } from 'common/src/models/user';

import AccessDeniedScreen from 'common/src/screens/misc/AccessDeniedScreen';
import NotFoundScreen from 'common/src/screens/misc/NotFoundScreen';
import { MainLayout } from '../components/layout/MainLayout';
import EventTemplateEditorScreen from '../screens/events/eventTemplate/EventTemplateEditorScreen';
import EventTemplateGroupEditorScreen from '../screens/events/eventTemplateGroup/EventTemplateGroupEditorScreen';
import FreeTicketViewerScreen from '../screens/events/misc/FreeTicketViewerScreen';
import TicketOrderScreen from '../screens/events/ticketOrder/TicketOrderScreen';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/login/LoginScreen';
import CheckInQRScannerNextScreen from '../screens/standalone/checkin/CheckinQRScannerNextScreen';
import CheckInQRScannerScreen from '../screens/standalone/checkin/CheckinQRScannerScreen';
import CheckInScreen from '../screens/standalone/checkin/CheckInScreen';
import centralScreens from './centralScreens';

export default function RootNavigator({ loggedIn, userRole }: {
  loggedIn: boolean;
  userRole: UserRole;
}) {
  const routes = [];
  if (userRole === UserRole.ADMIN || userRole === UserRole.LOGISTICS_MANAGER) {
    routes.push(<Route key='home' path='/' element={<HomeScreen />} />);
    routes.push(<Route
      key='editEventTemplate'
      path='/editEventTemplate/:eventTemplateId'
      element={<EventTemplateEditorScreen />}
    />);
    routes.push(<Route
      key='editEventTemplateGroup'
      path='/editEventTemplateGroup/:eventTemplateGroupId'
      element={<EventTemplateGroupEditorScreen />}
    />);
  }
  if (userRole === UserRole.ADMIN) {
    routes.push(<Route
      key='ticketOrders'
      path='/ticketOrders/:eventTemplateId'
      element={<TicketOrderScreen />}
    />);
  }
  centralScreens.forEach((centralScreen) => {
    if (centralScreen.allowedUserRoles.includes(userRole)) {
      routes.push(<Route
        key={centralScreen.path}
        path={centralScreen.path}
        element={centralScreen.element}
      />);
    }
  });

  return (
    <Routes>
      {
        loggedIn ? (
          <>
            <Route element={<MainLayout />}>
              {routes}
              <Route path='*' element={<NotFoundScreen />} />
            </Route>
          </>
        ) : (
          <>
            <Route path='*' element={<LoginScreen />} />
          </>
        )
      }
      <Route
        path='/checkIn/event/:eventTemplateId'
        element={<CheckInScreen />}
      />
      <Route
        path='/checkIn/scanner'
        element={<CheckInQRScannerScreen />}
      />
      <Route
        path='/checkIn/scannerNext'
        element={<CheckInQRScannerNextScreen />}
      />
      {loggedIn && userRole === UserRole.ADMIN && (
        <Route
          key='freeTicketViewer'
          path='/freeTicketViewer/:eventTemplateId'
          element={<FreeTicketViewerScreen />}
        />)
      }
      <Route
        path='/error'
        element={<AccessDeniedScreen />}
      />
    </Routes>
  );
}
