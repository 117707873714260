import React from 'react';
import { Box } from '@mui/material';

import { TicketOrderStatus } from 'common/src/models/event/ticket';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useTicketOrdersByEventTemplate } from '../../../../hooks/useResource';
import { refreshTicketOrders } from '../../../../redux/slices/event';

import { Text } from 'common/src/components/base';
import TicketOrderDetailsView from './TicketOrderDetailsView';

const LEGACY_QUALIFIED_TICKET_ID = /^\d{10}-[a-z]{4}$/;
const LEGACY_QUALIFIED_TICKET_ORDER_ID = /^\d{10}$/;

interface IProps {
  eventTemplateId: string;
  scannedData: string;
}

const ScanResult: React.FC<IProps> = ({ eventTemplateId, scannedData }) => {
  const dispatch = useAppDispatch();

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplateId);
  const cleanedScannedData = scannedData.trim();

  const isRefreshing = React.useRef(false);
  const lastRefreshedAt = React.useRef(0);
  const handleRefreshTicketOrders = React.useCallback(async () => {
    const now = Date.now();
    if (now - lastRefreshedAt.current < 30000) {
      // prevent spamming refresh. Only refresh at most once per 30s
      return;
    }
    if (isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;
    lastRefreshedAt.current = now;
    await dispatch(refreshTicketOrders({ eventTemplateId: eventTemplateId, onlyIfUncached: false }));
    isRefreshing.current = false;
  }, [dispatch, eventTemplateId]);

  if (!ticketOrders) {
    return (
      <Box>
        <Text centered variant='bold'>Loading...</Text>
      </Box>
    );
  }

  if (!cleanedScannedData) {
    return (
      <Box>
        <Text centered variant='bold'>Scan a QR Code to start</Text>
      </Box>
    );
  }

  const structuredEventData = parseEventData(cleanedScannedData);
  if (structuredEventData) {
    if (structuredEventData.eventTemplateId !== eventTemplateId) {
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered variant='bold'>Invalid QR Code</Text>
          <Text centered size='paragraph'>Reason: wrong event</Text>
          <Text centered size='paragraph'>QR Code for event: {structuredEventData.eventTemplateId}</Text>
          <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
        </Box>
      );
    }

    const ticketOrder = ticketOrders.find((ticketOrder_) => ticketOrder_.id === structuredEventData.ticketOrderId);
    if (!ticketOrder) {
      if (!structuredEventData.registeredTs || lastRefreshedAt.current < structuredEventData.registeredTs) {
        handleRefreshTicketOrders();
      }

      if (isRefreshing.current) {
        return (
          <Box bgcolor='#E8E7EA' p={20}>
            <Text centered variant='bold'>Unknown ticket order, refreshing...</Text>
            <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
          </Box>
        );
      } else {
        return (
          <Box bgcolor='#E8E7EA' p={20}>
            <Text centered variant='bold'>Invalid QR Code</Text>
            <Text centered size='paragraph'>Reason: unknown ticket order</Text>
            <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
          </Box>
        );
      }
    }

    if (ticketOrder.status === TicketOrderStatus.CONFIRMING) {
      handleRefreshTicketOrders();
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered variant='bold'>Ticket Order is still confirming</Text>
          <Text centered size='subnote'>Please wait a few minutes for the ticket order to be confirmed</Text>
        </Box>
      );
    }

    const ticket = ticketOrder.tickets.find((ticket_) => ticket_.id === structuredEventData.ticketId);
    if (!ticket) {
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered variant='bold'>Invalid QR Code</Text>
          <Text centered size='paragraph'>Reason: invalid ticket</Text>
          <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
        </Box>
      );
    }

    return (
      <TicketOrderDetailsView
        eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
      />
    );
  }

  if (LEGACY_QUALIFIED_TICKET_ID.test(cleanedScannedData)) {
    const parts = cleanedScannedData.split('-');
    const ticketOrderId = parts[0];
    const ticketId = parts[1];

    const ticketOrder = ticketOrders ?
      ticketOrders.find((ticketOrder_) => ticketOrder_.id === ticketOrderId) :
      null;
    const ticket = ticketOrder ? ticketOrder.tickets.find((ticket_) => ticket_.id === ticketId) : null;

    if (!ticketOrder) {
      handleRefreshTicketOrders();
      if (isRefreshing.current) {
        return (
          <Box bgcolor='#E8E7EA' p={20}>
            <Text centered variant='bold'>Unknown ticket order, refreshing...</Text>
            <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
          </Box>
        );
      } else {
        return (
          <Box bgcolor='#E8E7EA' p={20}>
            <Text centered variant='bold'>Invalid QR Code</Text>
            <Text centered size='paragraph'>Reason: unknown ticket order</Text>
            <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
          </Box>
        );
      }
    }

    if (ticketOrder.status === TicketOrderStatus.CONFIRMING) {
      handleRefreshTicketOrders();
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered variant='bold'>Ticket Order is still confirming</Text>
          <Text centered size='subnote'>Please wait a few minutes for the ticket order to be confirmed</Text>
        </Box>
      );
    }

    if (!ticket) {
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered variant='bold'>Invalid QR Code</Text>
          <Text centered size='paragraph'>Reason: invalid ticket</Text>
          <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
        </Box>
      );
    }

    return (
      <TicketOrderDetailsView
        eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
      />
    );
  }

  if (LEGACY_QUALIFIED_TICKET_ORDER_ID.test(cleanedScannedData)) {
    const ticketOrder = ticketOrders ?
      ticketOrders.find((ticketOrder_) => ticketOrder_.id === cleanedScannedData) :
      null;

    if (ticketOrder) {
      return (
        <Box bgcolor='#E8E7EA' p={20}>
          <Text centered size='paragraph'>See attendent to checkin</Text>
          <Text centered size='subnote'>Ticket Order ID: {cleanedScannedData}</Text>
        </Box>
      );
    }

    return (
      <Box bgcolor='#E8E7EA' p={20}>
        <Text centered variant='bold'>Invalid QR Code (make sure it is for this event)</Text>
      </Box>
    );
  }

  return (
    <Box bgcolor='#E8E7EA' p={20}>
      <Text centered variant='bold'>Invalid QR Code</Text>
      <Text centered size='paragraph'>Reason: not Taro QR code</Text>
      <Text centered size='subnote' mt={20}>Scanned data: {cleanedScannedData}</Text>
    </Box>
  );
};

type StructuredEventData = {
  eventTemplateId: string;
  ticketOrderId: string;
  ticketId: string;
  registeredTs: number;
};

function parseEventData(cleanedScannedData: string): StructuredEventData | null {
  try {
    const data = JSON.parse(cleanedScannedData);

    if (data.etid && data.toid && data.tid) {
      return {
        eventTemplateId: data.etid,
        ticketOrderId: data.toid,
        ticketId: data.tid,
        registeredTs: data.rts || 0,
      };
    } else {
      // not correct json format
      return null;
    }
  } catch (e) {
    // not a json
    return null;
  }
}

export default ScanResult;
