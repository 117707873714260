import React from 'react';
import { Box, Link, ListItem, Stack, SxProps, Theme } from '@mui/material';

import { Colors, ComponentStyles } from 'common/src/constants';
import {
  getCostAggregation, getSelectedTicketOptions, resolveTicketOption,
} from 'common/src/models/event';
import {
  getTicketStatusLabel, getTicketVirtualStatus, Ticket, TicketOrder, TicketOrderStatus,
  TicketStatus,
} from 'common/src/models/event/ticket';
import { getHumanReadableDateTime } from 'common/src/utils/time';
import { useEventTemplate } from '../../../../hooks/useResource';

import { Text } from 'common/src/components/base';

interface IProps {
  eventTemplateId: string;
  ticketOrder: TicketOrder;
  ticket: Ticket;
}

const TicketOrderDetailsView: React.FC<IProps> = ({ eventTemplateId, ticketOrder, ticket }) => {
  const eventTemplate = useEventTemplate(eventTemplateId);

  const [showDetails, setShowDetails] = React.useState(false);
  React.useEffect(() => {
    // Reset whenever the ticket or ticket order changes
    setShowDetails(false);
  }, [ticketOrder, ticket]);

  if (!eventTemplate) {
    return null;
  }

  const virtualStatus = getTicketVirtualStatus(ticketOrder, ticket);
  const statusLabel = getTicketStatusLabel(virtualStatus, ticketOrder);
  let checkedInTs = 0;
  if (ticketOrder.status === TicketOrderStatus.CONFIRMED && ticket.status === TicketStatus.CHECKED_IN) {
    checkedInTs = ticket.checkedInTs;
  }
  const costAggregation = getCostAggregation(Object.values(ticketOrder.costDetails.ticketIdToCost));

  const ticketOption = resolveTicketOption(eventTemplate, ticket.ticketOptionId);

  const orderDetailsView = (
    <Box>
      <DataRow label='Order Details' sx={{ mt: 20 }} />
      {getSelectedTicketOptions(ticketOrder).map((selectedTicketOption, idx) => {
        if (!selectedTicketOption.quantity) {
          return null;
        }

        const ticketOption = resolveTicketOption(eventTemplate, selectedTicketOption.id);
        return (
          <ListItem key={'o-' + idx} sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
            {ticketOption.name} x{selectedTicketOption.quantity} {selectedTicketOption.seatId ? `(座位: ${selectedTicketOption.seatId})` : ''}
          </ListItem>
        );
      })}

      <DataRow label='Order Cost' />
      <ListItem sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
              Subtotal: ${(costAggregation.subtotalInCents/100).toFixed(2)}
      </ListItem>

      {costAggregation.costAdjustors.map((costAdjustor, idx) => {
        return (
          <ListItem key={'d-' + idx} sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
                  Discount ({costAdjustor.adjustorName}): -${(-costAdjustor.adjustmentInCents/100).toFixed(2)}
          </ListItem>
        );
      })}

      {costAggregation.taxInCents > 0 && (
        <ListItem sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
                Tax: ${(costAggregation.taxInCents/100).toFixed(2)}
        </ListItem>
      )}

      <ListItem sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
              Fees: ${(costAggregation.feeInCents/100).toFixed(2)}
      </ListItem>

      <ListItem sx={{ display: 'list-item', py: 0, fontSize: ComponentStyles.text.paragraph.fontSize }}>
              Total: ${(costAggregation.finalCostInCents/100).toFixed(2)}
      </ListItem>
    </Box>
  );

  return (
    <Box>
      <DataRow label='Ticket Status' content={
        <Box bgcolor={statusLabel.statusLabelColor} px={8} borderRadius={5}>
          <Text size='paragraph'>{virtualStatus.replace('_', ' ')}</Text>
        </Box>
      } />
      {checkedInTs > 0 && (
        <DataRow
          label='Check In Time'
          content={getHumanReadableDateTime(checkedInTs, eventTemplate.eventTimeZone)}
        />
      )}
      <DataRow label='Order Id' sx={{ mt: 20 }} content={ticketOrder.id} />
      <DataRow label='Ticket Id' content={ticket.id} />
      <DataRow label='Ticket Type' content={ticketOption.name} />
      {ticket.seatId && (
        <DataRow label='Seat' content={ticket.seatId} />
      )}

      {showDetails ? orderDetailsView : (
        <Box sx={{ mt: 20 }}>
          <Link
            onClick={() => setShowDetails(true)}
            sx={{ cursor: 'pointer' }}
          >
            <Text size='paragraph'>Show Order Details</Text>
          </Link>
        </Box>
      )}
    </Box>
  );
};


interface IDataRowProps {
  sx?: SxProps<Theme>;
  label: string;
  content?: string | JSX.Element;
}

const DataRow: React.FC<IDataRowProps> = ({ sx, label, content }) => {
  return (
    <Stack direction='row' alignItems='center' sx={sx} mt={3}>
      <Box mr={5} bgcolor={Colors.DEFAULT_BACKGROUND} px={8} py={2} borderRadius={2}>
        <Text size='note'>{label}</Text>
      </Box>
      {content && (typeof content === 'string' ? <Text size='paragraph'>{content}</Text> : content)}
    </Stack>
  );
};


export default TicketOrderDetailsView;
