import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { getTicketVirtualStatus, resolveTicketOption, TicketVirtualStatus } from 'common/src/models/event/ticket';
import { getHumanReadableDateTime } from 'common/src/utils/time';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useEventTemplate, useTicketOrdersByEventTemplate } from '../../../../hooks/useResource';
import { checkInTickets, refreshTicketOrders } from '../../../../redux/slices/event';

import { Text } from 'common/src/components/base';
import TicketOrderDetailsView from './TicketOrderDetailsView';

const LEGACY_QUALIFIED_TICKET_ID = /^\d{10}-[a-z]{4}$/;
const LEGACY_QUALIFIED_TICKET_ORDER_ID = /^\d{10}$/;

interface IProps {
  eventTemplateId: string;
  scannedData: string;
  onAction: (action: 'success' | 'error') => void;
}

const ScanResult: React.FC<IProps> = ({ eventTemplateId, scannedData, onAction }) => {
  const dispatch = useAppDispatch();

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplateId);
  const eventTemplate = useEventTemplate(eventTemplateId);
  const cleanedScannedData = scannedData.trim();

  const isRefreshing = React.useRef(false);
  const lastRefreshedAt = React.useRef(0);
  const handleRefreshTicketOrders = React.useCallback(async () => {
    const now = Date.now();
    if (now - lastRefreshedAt.current < 5000) {
      // prevent spamming refresh. Only refresh at most once per 5s
      return;
    }
    if (isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;
    lastRefreshedAt.current = now;
    await dispatch(refreshTicketOrders({ eventTemplateId: eventTemplateId, onlyIfUncached: false }));
    isRefreshing.current = false;
  }, [dispatch, eventTemplateId]);

  const handleCheckIn = React.useCallback(async (ticketOrderId: string, ticketId: string) => {
    await dispatch(checkInTickets({
      ticketOrderId: ticketOrderId,
      ticketIds: [ticketId],
    }));
  }, [dispatch]);

  const [showTicketDetails, setShowTicketDetails] = React.useState(false);
  const handleToggleTicketDetails = React.useCallback(() => {
    setShowTicketDetails((prev) => !prev);
  }, []);

  const [meta, setMeta] = React.useState<{
    status: 'success' | 'error' | 'transition';
    title: string;
    context?: string;
    detailView?: React.ReactNode;
    disableDetails?: boolean;
  }>({
    status: 'transition',
    title: '',
  });

  React.useEffect(() => {
    if (!ticketOrders || !eventTemplate) {
      setMeta({
        status: 'transition',
        title: 'Loading...',
        disableDetails: true,
      });
      return;
    }

    if (!cleanedScannedData) {
      setMeta({
        status: 'transition',
        title: 'Ready for check in',
        disableDetails: true,
      });
      return;
    }

    const structuredEventData = parseEventData(cleanedScannedData);
    if (structuredEventData) {
      if (structuredEventData.eventTemplateId !== eventTemplateId) {
        onAction('error');
        setMeta( {
          status: 'error',
          title: 'Ticket for different event',
          detailView: (
            <Box>
              <Text centered size='paragraph'>QR Code is for event: {structuredEventData.eventTemplateId}</Text>
            </Box>
          ),
        });
        return;
      }

      const ticketOrder = ticketOrders.find((ticketOrder_) => ticketOrder_.id === structuredEventData.ticketOrderId);
      if (!ticketOrder) {
        if (!structuredEventData.registeredTs || lastRefreshedAt.current < structuredEventData.registeredTs) {
          handleRefreshTicketOrders();
        }

        if (isRefreshing.current) {
          setMeta( {
            status: 'transition',
            title: 'Unknown ticket order, refreshing...',
          });
          return;
        } else {
          onAction('error');
          setMeta( {
            status: 'error',
            title: 'Invalid ticket order',
          });
          return;
        }
      }

      const ticket = ticketOrder.tickets.find((ticket_) => ticket_.id === structuredEventData.ticketId);
      if (!ticket) {
        onAction('error');
        setMeta( {
          status: 'error',
          title: 'Invalid ticket',
        });
        return;
      }

      const ticketOption = resolveTicketOption(eventTemplate, ticket.ticketOptionId);
      let ticketInfo = ticketOption.name;
      if (ticket.seatId) {
        ticketInfo += ' (' + ticket.seatId + ')';
      } else {
        ticketInfo += ' (' + ticket.id + ')';
      }

      const virtualStatus = getTicketVirtualStatus(ticketOrder, ticket);
      switch (virtualStatus) {
        case TicketVirtualStatus.CONFIRMING:
          onAction('error');
          handleRefreshTicketOrders();
          setMeta( {
            status: 'error',
            title: 'Ticket is confirming',
            context: 'Please wait a few minutes for the ticket order to be confirmed',
          });
          return;
        case TicketVirtualStatus.CANCELLED:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order was cancelled',
          });
          return;
        case TicketVirtualStatus.ERROR:
        case TicketVirtualStatus.INIT:
        case TicketVirtualStatus.PENDING:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order has error',
          });
          return;
        case TicketVirtualStatus.WAITLIST:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order is waitlisted',
          });
          return;
        case TicketVirtualStatus.CONFIRMED:
          handleCheckIn(ticketOrder.id, ticket.id);
          setMeta({
            status: 'transition',
            title: 'Checking in...',
            context: ticketInfo,
            detailView: (
              <TicketOrderDetailsView
                eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
              />
            ),
          });
          return;
        case TicketVirtualStatus.CHECKED_IN:
          if (Date.now() - ticket.checkedInTs > 60000) {
            onAction('error');
            setMeta( {
              status: 'error',
              title: 'Ticket was already checked in',
              context: 'Checked in at ' + getHumanReadableDateTime(ticket.checkedInTs, eventTemplate.eventTimeZone),
              detailView: (
                <TicketOrderDetailsView
                  eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
                />
              ),
            });
            return;
          }
          // Otherwise it's recent checkin, so we show success message
      }

      onAction('success');
      setMeta( {
        status: 'success',
        title: 'Checked In',
        context: ticketInfo,
        detailView: (
          <TicketOrderDetailsView
            eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
          />
        ),
      });
      return;
    }

    if (LEGACY_QUALIFIED_TICKET_ID.test(cleanedScannedData)) {
      const parts = cleanedScannedData.split('-');
      const ticketOrderId = parts[0];
      const ticketId = parts[1];

      const ticketOrder = ticketOrders.find((ticketOrder_) => ticketOrder_.id === ticketOrderId);
      if (!ticketOrder) {
        handleRefreshTicketOrders();
        if (isRefreshing.current) {
          setMeta( {
            status: 'transition',
            title: 'Unknown ticket order, refreshing...',
          });
          return;
        } else {
          onAction('error');
          setMeta( {
            status: 'error',
            title: 'Invalid ticket order',
          });
          return;
        }
      }

      const ticket = ticketOrder.tickets.find((ticket_) => ticket_.id === ticketId);
      if (!ticket) {
        onAction('error');
        setMeta( {
          status: 'error',
          title: 'Invalid ticket',
        });
        return;
      }

      const ticketOption = resolveTicketOption(eventTemplate, ticket.ticketOptionId);
      let ticketInfo = ticketOption.name;
      if (ticket.seatId) {
        ticketInfo += ' (' + ticket.seatId + ')';
      } else {
        ticketInfo += ' (' + ticket.id + ')';
      }

      const virtualStatus = getTicketVirtualStatus(ticketOrder, ticket);
      switch (virtualStatus) {
        case TicketVirtualStatus.CONFIRMING:
          onAction('error');
          handleRefreshTicketOrders();
          setMeta( {
            status: 'error',
            title: 'Ticket is confirming',
            context: 'Please wait a few minutes for the ticket order to be confirmed',
          });
          return;
        case TicketVirtualStatus.CANCELLED:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order was cancelled',
          });
          return;
        case TicketVirtualStatus.ERROR:
        case TicketVirtualStatus.INIT:
        case TicketVirtualStatus.PENDING:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order has error',
          });
          return;
        case TicketVirtualStatus.WAITLIST:
          onAction('error');
          setMeta({
            status: 'error',
            title: 'Ticket order is waitlisted',
          });
          return;
        case TicketVirtualStatus.CONFIRMED:
          handleCheckIn(ticketOrder.id, ticket.id);
          setMeta({
            status: 'transition',
            title: 'Checking in...',
            context: ticketInfo,
            detailView: (
              <TicketOrderDetailsView
                eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
              />
            ),
          });
          return;
        case TicketVirtualStatus.CHECKED_IN:
          if (Date.now() - ticket.checkedInTs > 60000) {
            onAction('error');
            setMeta( {
              status: 'error',
              title: 'Ticket was already checked in',
              context: 'Checked in at ' + getHumanReadableDateTime(ticket.checkedInTs, eventTemplate.eventTimeZone),
              detailView: (
                <TicketOrderDetailsView
                  eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
                />
              ),
            });
            return;
          }
          // Otherwise it's recent checkin, so we show success message
      }

      onAction('success');
      setMeta( {
        status: 'success',
        title: 'Checked In',
        context: ticketInfo,
        detailView: (
          <TicketOrderDetailsView
            eventTemplateId={eventTemplateId} ticketOrder={ticketOrder} ticket={ticket}
          />
        ),
      });
      return;
    }

    if (LEGACY_QUALIFIED_TICKET_ORDER_ID.test(cleanedScannedData)) {
      const ticketOrder = ticketOrders ?
        ticketOrders.find((ticketOrder_) => ticketOrder_.id === cleanedScannedData) :
        null;

      if (!ticketOrder) {
        onAction('error');
        setMeta( {
          status: 'error',
          title: 'Invalid ticket',
        });
        return;
      }

      onAction('error');
      setMeta({
        status: 'error',
        title: 'See attendent to checkin',
        context: 'Ticket Order ID: ' + cleanedScannedData,
      });
      return;
    }

    onAction('error');
    setMeta( {
      status: 'error',
      title: 'Not a valid Taro QR code',
    });
    return;
  }, [cleanedScannedData, eventTemplate, eventTemplateId, handleCheckIn, handleRefreshTicketOrders, onAction, ticketOrders]);

  return (
    <Box >
      <ResultBanner
        status={meta.status}
        title={meta.title}
        context={meta.context}
        onToggleTicketDetails={!meta.disableDetails ? handleToggleTicketDetails : undefined}
      />
      {!meta.disableDetails && showTicketDetails && (
        <Box bgcolor='#E8E7EA' p={20}>
          {meta.detailView ? (
            <Box>
              {meta.detailView}
              <Text size='subnote' sx={{ overflowWrap: 'break-word' }} mt={20}>Scanned data: {cleanedScannedData}</Text>
            </Box>
          ) : (
            <Box>
              <Text size='subnote' sx={{ overflowWrap: 'break-word' }}>Scanned data: {cleanedScannedData}</Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

interface IResultBannerProps {
  status: 'init' | 'success' | 'error' | 'transition';
  title: string;
  context?: string;
  onToggleTicketDetails?: () => void;
}

const ResultBanner: React.FC<IResultBannerProps> = ({ status, title, context, onToggleTicketDetails }) => {
  return (
    <Stack direction='row'
      justifyContent='space-between' alignItems='center' py={10}
      bgcolor={status === 'success' ? '#6FD25A' : status === 'error' ? Colors.ERROR : Colors.GREY500}
    >
      <Stack direction='row' alignItems='center' pl={20}>
        {status === 'success' ?
          <CheckCircleIcon sx={{ color: 'white', fontSize: 35, mr: 17 }} /> :
          status === 'error' ?
            <CancelIcon sx={{ color: 'white', fontSize: 35, mr: 17 }} /> :
            null
        }
        <Box>
          <Text size='paragraph' variant='bold' color='white'>{title}</Text>
          <Text size='paragraph' color='white'>{context}</Text>
        </Box>
      </Stack>
      {!!onToggleTicketDetails && (
        <IconButton sx={{ mr: -10 }} onClick={onToggleTicketDetails}>
          <MoreVertIcon sx={{ color: 'white', fontSize: 25 }} />
        </IconButton>
      )}
    </Stack>
  );
};

type StructuredEventData = {
  eventTemplateId: string;
  ticketOrderId: string;
  ticketId: string;
  registeredTs: number;
};

function parseEventData(cleanedScannedData: string): StructuredEventData | null {
  try {
    const data = JSON.parse(cleanedScannedData);

    if (data.etid && data.toid && data.tid) {
      return {
        eventTemplateId: data.etid,
        ticketOrderId: data.toid,
        ticketId: data.tid,
        registeredTs: data.rts || 0,
      };
    } else {
      // not correct json format
      return null;
    }
  } catch (e) {
    // not a json
    return null;
  }
}

export default ScanResult;
